import Icon from 'components/shared/icon';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { keys, set } from 'lib/utils/localStorage';
import RichText from 'components/shared/richtext';
import { useUI, useCustomer, useLogoutCustomer } from 'hooks';
import { getAllowedParams, serializeURIParams } from 'lib/utils/helpers';
import styles from './localePopup.module.scss';

const LocalePopup = ({ globalModules, locale, pageData }) => {
  const { message, ctaLabel } = globalModules?.['locale-popup'] || {};
  const { localePopupData, closeLocalePopup, displayLocalePopup } = useUI();
  const router = useRouter();
  const { customer } = useCustomer();
  const [logoutCustomer] = useLogoutCustomer(locale);

  const getLocaleFromSlug = slug => slug?.replace(/\//g, '')?.toLowerCase();
  const generateHref = slug => {
    if (!pageData) {
      return slug === '/' ? slug : `/${getLocaleFromSlug(slug)}/`;
    }

    let queryParams = '';

    if (Object.keys(router.query).length) {
      const filteredParams = Object.keys(router.query)
        .filter(key => getAllowedParams().includes(key.toLowerCase()))
        .reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = router.query[key];
          return obj;
        }, {});

      const params = new URLSearchParams(serializeURIParams(filteredParams));
      queryParams = params.toString() !== '' ? `?${params.toString()}` : '';
    }

    // pageHrefObject contains of locale and slug
    const [pageHrefObject] = pageData.href.filter(
      ({ locale: hrefLocale }) => hrefLocale === getLocaleFromSlug(slug === '/' ? 'en' : slug)
    );
    // locale from pageSlug
    const finalLocale = pageHrefObject?.locale === 'en' ? '' : `/${pageHrefObject?.locale}`;
    const generateFinalSlug = () => {
      if (pageHrefObject?.slug) {
        return pageHrefObject?.slug?.startsWith('/')
          ? pageHrefObject.slug
          : `/${pageHrefObject.slug}`;
      }
      return '';
    };
    // add the page if it is in router.query
    const preservedPage = router.query?.page ? `/page/${router.query?.page}` : '';

    return `${finalLocale}${generateFinalSlug()}${preservedPage}${queryParams}`;
  };

  const handleClose = () => {
    const localeFromSlug = getLocaleFromSlug(localePopupData === '/' ? 'en' : localePopupData);
    document.body.style.overflow = 'auto';
    closeLocalePopup();

    // non included locales have '/' slug. to stay on the international version hardcoding en
    router
      .push(
        {
          pathname: generateHref(localeFromSlug),
        },
        null,
        {
          locale: localeFromSlug,
        }
      )
      .then(() => {
        set(keys.USER_LOCALE_PREFERENCE, `${localeFromSlug}`);
        // logout customer because nl and fr are different stores
        if (customer) {
          logoutCustomer(customer?.customerAccessToken?.accessToken);
        }
      });
  };

  useEffect(() => {
    if (displayLocalePopup) {
      document.body.style.overflow = 'hidden';
    }
  }, [displayLocalePopup]);

  if (!displayLocalePopup) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <button
          type="button"
          className={styles.closeBtn}
          onClick={() => {
            handleClose();
          }}
        >
          <Icon type="close" />
        </button>

        <RichText text={message?.text?.json} className={styles.message} />

        {ctaLabel?.text && (
          <button
            type="button"
            onClick={() => {
              handleClose();
            }}
            className={styles.button}
          >
            {ctaLabel.text}
          </button>
        )}
      </div>
    </div>
  );
};

export default LocalePopup;
