import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import SiteLink from 'components/shared/sitelink';
import { checkIsWindowsOS, countryCodeEmoji } from 'lib/utils/helpers';
import { useUI, useCustomer, useLogoutCustomer } from 'hooks';
import { useRouter } from 'next/router';
import { useSettings } from 'contexts';
import { keys, set } from 'lib/utils/localStorage';
import styles from './localeSelector.module.scss';

const LocaleSelector = ({ locale, allLocales }) => {
  const { countryCode, setCountryCode } = useSettings();
  // todo countryCode will be Contentful sys.id

  const [selectedLocale, setSelectedLocale] = useState(
    countryCode.toLowerCase() === locale ? countryCode : locale
  );
  const [countrySelectorIsOpen, setCountrySelectorIsOpen] = useState(false);
  const [isWindows, setIsWindows] = useState('');

  const { openLocalePopup } = useUI();

  const router = useRouter();
  const { customer } = useCustomer();
  const [logoutCustomer] = useLogoutCustomer(locale);

  useEffect(() => {
    setIsWindows(checkIsWindowsOS());
  }, []);

  // todo change countryCode and selectedLocale to use sys.id
  useEffect(() => {
    if (countryCode !== selectedLocale) {
      setSelectedLocale(countryCode.toLowerCase() === locale ? countryCode : locale);
    }
  }, [countryCode, locale]);

  if (!allLocales) return null;

  // Utility functions.
  const getLocaleFromSlug = slug => slug?.replace(/\//g, '')?.toLowerCase();
  const getCountryName = () => {
    // if el.slug === na set the default to US because both Canada and US have the same slug
    const currentLocale = allLocales.find(el => {
      // if selected in locale selector
      if (selectedLocale === 'CA') {
        return el.countryCode === selectedLocale;
      }
      if (selectedLocale === 'BE' && locale === 'fr-be') {
        return el.slug === '/fr-be/';
      }
      if (selectedLocale === 'BE' && locale === 'nl-be') {
        return el.slug === '/nl-be/';
      }
      // if was set from locale
      if (selectedLocale === 'na') {
        return el.countryCode === 'US';
      }
      if (selectedLocale === 'de') {
        return el.countryCode === 'DE';
      }

      return getLocaleFromSlug(el?.slug) === selectedLocale || el.countryCode === selectedLocale;
    })?.name;

    return currentLocale || '';
  };

  const isCountryAndLanguageLocale = cc => cc?.length >= 2 && cc.length <= 6;
  const getCountryFromLocale = cc => cc?.slice(-2);
  const getEmoji = cc =>
    isCountryAndLanguageLocale(cc) &&
    countryCodeEmoji(cc === 'fr-fr' ? 'tn' : getCountryFromLocale(cc)); // changing fr-fr to tn(Tunisia)

  const handleLanguageChange = (e, slug, cc) => {
    setCountrySelectorIsOpen(false);

    const localeFromSlug = getLocaleFromSlug(slug === '/' ? 'en' : slug);
    setSelectedLocale(cc);
    setCountryCode(cc);

    if (slug === '/' && localeFromSlug === 'en') {
      openLocalePopup(slug);
    } else {
      router.push({ pathname: slug }, null, { locale: localeFromSlug }).then(() => {
        set(keys.USER_LOCALE_PREFERENCE, localeFromSlug);
        // logout customer because nl and fr are different stores
        if (customer) {
          logoutCustomer(customer?.customerAccessToken?.accessToken);
        }
      });
    }
  };

  return (
    <nav className={styles.countrySelector} role="navigation" aria-label="Locale navigation">
      <div className={styles.selected}>
        <button type="button" onClick={() => setCountrySelectorIsOpen(true)}>
          <span
            role="img"
            aria-label={getCountryName(selectedLocale)?.title}
            className={styles.flag}
          >
            {getEmoji(selectedLocale)}
          </span>
          <span className={styles.label}>{getCountryName(selectedLocale) || ''}</span>
        </button>
      </div>
      <div
        className={cx(styles.dropdownBackdrop, { [styles.visible]: countrySelectorIsOpen })}
        onClick={() => setCountrySelectorIsOpen(false)}
        role="none"
      />
      <div className={cx(styles.dropdown, { [styles.visible]: countrySelectorIsOpen })}>
        {allLocales.map(({ externalLink, name, slug, countryCode: cc, sys }) => {
          if (externalLink === null) {
            return (
              // eslint-disable-next-line
              <div
                key={sys?.id}
                className={styles.localeContainer}
                onClick={e => handleLanguageChange(e, slug, cc)}
              >
                {!isWindows && (
                  <span role="img" aria-label={name} className={styles.flag}>
                    {getEmoji(cc)}
                  </span>
                )}
                {name}
              </div>
            );
          }
          return (
            // eslint-disable-next-line
            <div
              key={sys?.id}
              className={styles.localeContainer}
              onClick={() => setCountrySelectorIsOpen(false)}
            >
              <SiteLink key={sys?.id} external={`${externalLink}`}>
                {!isWindows && (
                  <span role="img" aria-label={name} className={styles.flag}>
                    {getEmoji(cc)}
                  </span>
                )}
                {name}
              </SiteLink>
            </div>
          );
        })}
      </div>
    </nav>
  );
};

export default LocaleSelector;
